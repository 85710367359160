<template>
  <st-side-page class="cashier-page">
    <template slot="side">
      <ul class="menu">
        <li class="menu-item" v-for="(menu, i) in menus" :key="i">
          <router-link :to="menu.path">{{ menu.label }}</router-link>
          <fa-icon :icon="['fal', 'chevron-right']"/>
        </li>
      </ul>
    </template>
    <router-view/>
  </st-side-page>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { path: '/cashier-opening', label: 'Abertura' },
        { path: '/cashier-operations', label: 'Aporte/Sangria' },
        { path: '/cashier-financial', label: 'Receita/Despesa' },
        { path: '/cashier-attendances', label: 'Atendimentos' },
        { path: '/cashier-referrals', label: 'Encaminhamentos' },
        { path: '/cashier-cash-flow', label: 'Fluxo de caixa' },
        { path: '/cashier-closure', label: 'Fechamento' },
        { path: '/cashier-configuration', label: 'Configuração' },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .cashier-page {
    &.side-page {
      .side-page-side {
        flex: 0 0 11rem;
      }
    }
    .menu {
      .router-link-exact-active {
        color: $primary-color;
        & + svg {
          color: $primary-color;
        }
      }
    }
  }
</style>
